<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/userUpload', query: { type: pagetype } }">用户上传</el-breadcrumb-item>
      <el-breadcrumb-item>{{ toptitle }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div style="width: 100%;text-align: left;margin-bottom: 20px;">
        <!-- <el-button type="primary" @click="distributeIll()" v-if="type == 'bj' || type == 'look'"
          >绑定疾病</el-button
        > -->
        <div style="line-height: 60px;" v-if="type == 'bj' || type == 'look'">
          <p style="float: left;margin: 0;">添加标签：</p>
          <el-tag v-for="tag in tags" :key="tag.id" closable @close="handleClose(tag)" style="margin-right: 8px;">
            {{ tag.tag }}
          </el-tag>
          <el-button class="button-new-tag" size="small" @click="add()">+ 添加</el-button>
          <!--  v-if="iscanaddtag" -->
        </div>
        <!-- <div style="line-height: 60px;" v-if="type == 'bj'">
					<p style="float: left;margin: 0;">添加二级标签：</p>
					<el-tag v-for="tag in tags" :key="tag.id" closable @close="handleClose(tag)" style="margin-right: 8px;">
						{{ tag.tag }}
					</el-tag>
					<el-button v-if="iscanaddtag" class="button-new-tag" size="small" @click="add()">+ 添加</el-button>
				</div> -->
        <!-- <el-button size="mini" type="primary" icon="el-icon-edit-outline" @click="add(id)">视频标签分类
                </el-button>
                <el-button size="mini" type="success" icon="el-icon-delete" @click="del1(id)">删除视频标签
                </el-button>  :disabled="disabled"-->
      </div>
      <el-divider></el-divider>
      <el-form style="width: 80%;text-align: left;" label-width="100px">
        <el-form-item label="选择分类">
          <el-select filterable v-model="videosort" clearable placeholder="请选择分类" style="width: 200px;">
            <el-option v-for="item in sortoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择类型">
          <el-select filterable v-model="videotype" clearable placeholder="请选择类型" style="width: 200px;">
            <el-option v-for="item in typeoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患者ID">
          <el-input v-model="cust_id"></el-input>
        </el-form-item>
        <el-form-item label="电话号">
          <el-input v-model="cust_tel"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select filterable v-model="sex" clearable placeholder="请选择性别" style="width: 200px;">
            <el-option v-for="item in sexoptions" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="age"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <upload :src.sync="headimg"></upload>
        </el-form-item>
        <el-form-item label="绑定疾病">
          <el-input style="width: 300px;margin-right: 20px;" v-model="ill_name"></el-input>
          <el-button type="primary" @click="distributeIll()" v-if="type != 'look'">绑定疾病</el-button>
        </el-form-item>
        <el-form-item label="绑定医生">
          <el-input placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search" style="width: 20%;float: left;" @clear="clearsearch">
          </el-input>
          <el-button type="primary" style="margin-left:20px;float: left;" @click="query">查询</el-button>
          <div style="width:100%;float: left;text-align: left;margin-top: 5px;">
            <el-radio-group v-model="radio">
              <el-radio @change="changedoc" style="width: 80px;margin: 5px 0;" v-for="item in list" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="封面图片">
          <upload1 :src.sync="pic" style="width:180px;float:left;"></upload1>
          <div style="position: relative;height: 180px;float:left;" v-if="picurl != ''">
            <div style="position:absolute;bottom: 0;width: 98px;height: 40px;left:0;z-index: 50;opacity: 0;" class="demo-image__preview">
              <el-image style="width: 100%;height: 100%;" :src="picurl" :preview-src-list="picsrcList">
              </el-image>
            </div>
            <el-button style="position:absolute;margin-left: 20px;bottom: 0;z-index: 10;">查看大图</el-button>
          </div>
        </el-form-item>
        <!-- <el-form-item label="内容" v-if="content_type == 1 || videotype == 1">
          <el-input type="textarea" v-model="content" :rows="5"></el-input>
        </el-form-item> -->
        <!-- 文章类型全部采用富文本 -->
        <el-form-item label="内容" v-if="videotype == 1">
          <FullEditor id="a" rows="10" upload="/"></FullEditor>
          <!-- <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" placeholder="请输入内容" v-model="textarea2"> -->
          <!-- </el-input> -->
        </el-form-item>
        <!-- 选择上传形式，图片或视频 -->

        <el-form-item label="视频地址" v-if="type == 'new' && videotype == 2">
          <el-input v-model="addurl"></el-input>
        </el-form-item>
        <el-form-item label="视频" v-if="content_type == 2">
          <el-input v-model="videourl" style="margin-bottom:10px;"></el-input>
          <video id="video1" :src="videourl" controls style="width: 80%;height:400px;"></video>
        </el-form-item>
        <el-form-item label="描述" v-if="content_type == 2 || videotype == 2">
          <el-input type="textarea" v-model="des" :rows="6"></el-input>
        </el-form-item>
        <el-form-item label="分享标题">
          <el-input v-model="sharetitle"></el-input>
        </el-form-item>
        <el-form-item label="分享图片">
          <upload1 :src.sync="sharepic" style="width:180px;float:left;"></upload1>
        </el-form-item>
        <el-form-item label="分享描述">
          <el-input type="textarea" :rows="2" maxlength="150" show-word-limit placeholder="请输入内容" v-model="sharecontent"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" v-if="type == 'bj' || type == 'new'" @click="sub1">确 定</el-button>
        <!-- <el-button type="primary" v-if="type != 'look'" @click="sub1">确 定</el-button> -->
      </div>
      <el-input v-model="doc_id" v-if="false"></el-input>
    </div>
    <!-- 绑定疾病 -->
    <el-dialog title="绑定疾病" :visible.sync="distribute">
      <el-form style="width: 50%;margin: 0 auto;text-align: left;">
        <el-form-item label="请选择疾病">
          <el-select filterable v-model="distributedp" clearable placeholder="请选择疾病" value-key="id" style="width: 130px;" @change="selectdistributedp">
            <el-option v-for="item in distributeoptions" :key="item.id" :label="item.name" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            distribute = false;
            distributedp = '';
          ">取 消</el-button>
        <el-button type="primary" @click="subdistribute">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 添加 -->
    <el-dialog :title="title1" :visible.sync="dialogFormVisible">
      <p class="videochoice">
        视频标签分类：
        <el-select filterable v-model="lables" clearable placeholder="请选择分类" style="width: 130px;" @change="selectlabels">
          <el-option v-for="item in typelable" :key="item.id" :label="item.tag" :value="item.id">
          </el-option>
        </el-select>
      </p>
      <p class="videochoice">
        排序：
        <el-input style="display:inlin-block;width:130px;" v-model="sort" placeholder="请输入正整数"></el-input>
        <span style="margin-top:5px;margin-left:50px;color:#909399;float:left;width:100%;font-size:12px;">数字越小越靠前</span>
      </p>
      <p class="videochoice">
        选择权重级别：
        <el-radio v-model="rank" label="1">一级</el-radio>
        <el-radio v-model="rank" label="2">二级</el-radio>
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            dialogFormVisible = false;
            form = {};
          ">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "../components/upload";
import upload1 from "../components/upload";
import FullEditor from "../components/FullEditor.vue";
export default {
  components: {
    upload,
    upload1,
    FullEditor,
  },
  inject: ["reload"],
  data() {
    return {
      id: "",
      type: "",
      toptitle: "",
      looktype: "", //发布内容
      title: "",
      content: "",
      pic: "",
      videourl: "",
      des: "",
      doc_id: "",
      videosort: "",
      ill_name: "",
      sortoptions: [
        { id: 1, name: "反馈视频" },
        { id: 2, name: "寻医视频" },
      ],
      videotype: "",
      typeoptions: [
        { id: 1, name: "文章" },
        { id: 2, name: "视频" },
      ],
      form: {
        tag_ids: "",
        video_id: "",
      },
      title1: "标签分类",
      dialogFormVisible: false,
      //分类标签
      lables: "",
      typelable: [],
      dp: "", //科室ID
      dpoptions: [],
      content_type: "",
      addurl: "",
      arr: "",
      search: "",
      radio: "",
      list: [],
      distributedp: "", //科室ID
      distributeoptions: [],
      distribute: false,
      distributeID: "",
      headimg: "",
      name: "",
      age: "",
      sex: "",
      sexoptions: [
        { id: "男", label: "男" },
        { id: "女", label: "女" },
      ],
      tags: [],
      choicelabel: "",
      cust_id: "",
      picurl: "",
      picsrcList: [],
      sort: "",
      rank: "",
      iscanaddtag: false,
      disabled: false,
      pagetype: sessionStorage.getItem("pagetype"),
      cust_tel: "",
      sharetitle: "", //分享标题
      sharepic: "", //分享图片
      sharecontent: "", //分享内容
    };
  },
  mounted() {
    this.getdplist1();
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.type == "bj") {
      this.getdetail();
      this.toptitle = "编辑";
      this.gettags();
    } else if (this.type == "new") {
      this.toptitle = "添加";
    } else {
      this.disabled = true;
      this.toptitle = "查看";
      this.getdetail();
      this.gettags();
    }
  },
  methods: {
    telxing(val) {
      var str = "";
      if (val == null || val == "null" || val === "" || val == undefined) {
        str = "";
      } else {
        str = val.substring(0, 3) + "****" + val.substring(7);
      }
      return str;
    },
    gettags() {
      this.axios
        .get("/tag/get_gu_tag_videotag_by_videoid?video_id=" + this.id)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.result.length > 0) {
              this.tags = res.data.result;
            } else {
              this.iscanaddtag = true;
            }
          } else {
            this.iscanaddtag = true;
          }
        });
    },
    getillList() {
      this.axios.get("/gu/get_gu_ill_lib").then((rs) => {
        if (rs.data.code == 0) {
          this.distributeoptions = rs.data.result;
        }
      });
    },
    add() {
      this.dialogFormVisible = true;
      this.rank = "";
      this.sort = "";
      this.lables = "";
    },
    sub() {
      let url = "/tag/insert_gu_tag_videotag";
      let str = "添加成功";
      this.axios
        .post(
          url,
          this.qs.stringify({
            video_id: this.id,
            tag_ids: this.lables,
            rank: this.rank,
            sort: this.sort,
          })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.$message({
              message: str,
              type: "success",
            });
            this.handleInputConfirm(this.choicelabel);
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectlabels(value) {
      for (let m = 0; m < this.typelable.length; m++) {
        const element = this.typelable[m];
        if (element.id == value) {
          this.choicelabel = element;
          return;
        }
      }
    },
    handleInputConfirm(val) {
      var arr = [];
      arr = this.tags;
      arr.push(val);
      this.tags = [];
      this.tags = arr;
    },
    getdplist1() {
      this.axios.get("/tag/get_gu_tag").then((res) => {
        if (res.data.code == 0) {
          this.typelable = res.data.result;
        }
      });
    },
    handleClose(tag) {
      this.del1(tag);
    },
    del1(row) {
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var tagid = "";
          if (row.tag_id) {
            tagid = row.tag_id;
          } else {
            tagid = row.id;
          }
          this.axios
            .post(
              "/tag/delete_gu_tag_videotag",
              this.qs.stringify({ video_id: this.id, tag_id: tagid })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.tags.splice(this.tags.indexOf(row), 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getdetail() {
      this.axios.get("/item/get_gu_item_by_id?id=" + this.id).then((res) => {
        if (res.data.code == 0) {
          this.arr = res.data.result;
          this.title = this.arr.title;
          this.pic = this.arr.pic;
          this.looktype = this.arr.content_type;
          this.content_type = this.arr.content_type;
          if (this.arr.content_type == 2) {
            //2视频，1文章
            this.videourl = this.arr.content;
            this.des = this.arr.des;
          } else {
            // this.content = this.arr.content;
            let txt = this.htmlDecode(this.arr.content);
            this.$nextTick(() =>
              setTimeout(() => {
                tinymce.activeEditor.setContent(txt);
              }, 300)
            );
          }
          this.videotype = this.arr.content_type;
          this.videosort = this.arr.type; //2寻医1反馈
          if (
            this.arr.ill_lib_id != "" &&
            this.arr.ill_lib_id != null &&
            this.arr.ill_lib_id != undefined
          ) {
            this.dp = this.arr.ill_lib_id;
          }
          this.search = this.arr.doc_name;
          this.doc_id = this.arr.doc_id;
          this.ill_name = this.arr.ill_name;
          this.age = this.arr.age;
          this.name = this.arr.name;
          this.sex = this.arr.sex;
          this.headimg = this.arr.headimg;
          this.cust_id = this.arr.cust_id;
          this.picurl = this.arr.pic;
          this.picsrcList.push(this.arr.pic);
          this.cust_tel = this.telxing(this.arr.cust_tel);

          this.sharetitle = this.arr.share_title; //分享标题
          this.sharepic = this.arr.share_img; //分享图片
          this.sharecontent = this.arr.share_content; //分享描述
        }
      });
    },
    query() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.search, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.list = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changedoc() {
      this.doc_id = this.radio;
    },
    clearsearch() {
      this.search = "";
      this.doc_id = "";
      this.radio = "";
      this.list = [];
    },
    cancel() {
      if (this.type == "new" || this.type == "bj") {
        this.$router.push({ path: "/userUpload", query: { type: 1 } });
      } else {
        this.$router.push({ path: "/userUpload", query: { type: 2 } });
      }
    },
    sub1() {
      var url = "",
        str = "";
      var form = {
        title: this.title,
        pic: this.pic,
        type: this.videosort,
        content_type: this.videotype,
        doc_id: this.doc_id,
        ill_lib_id: this.dp,
        name: this.name,
        sex: this.sex,
        age: this.age,
        headimg: this.headimg,

        share_title: this.sharetitle, //分享标题
        share_img: this.sharepic, //分享图片
        share_content: this.sharecontent, //分享描述
      };
      if (this.type == "new") {
        url = "/item/insert_gu_item";
        str = "添加成功";
        form.cust_id = "";
        form.x_cust_id = "";
        form.cust_id = this.cust_id;
      } else {
        url = "/item/update_gu_item";
        str = "修改成功";
        form.id = this.id;
        form.cust_id = this.arr.cust_id;
        form.x_cust_id = this.arr.x_cust_id;
      }
      if (this.videotype == 2) {
        if (this.type == "new") {
          form.content = this.addurl;
        } else {
          form.content = this.videourl;
        }
        form.des = this.des;
      } else {
        //文章1
        let data = tinyMCE.activeEditor.getContent();
        this.content = this.htmlEncode(data);
        form.content = this.content;
        form.des = "";
      }
      // if (this.videotype == 2 && this.type == "new") {
      //   form.content = this.addurl;
      // } else if (this.videotype == 1 && this.type == "new") {
      //   form.content = this.content;
      // }
      if (form.cust_id == "" || form.cust_id == 0) {
        if (form.name == "" || form.sex == "" || form.age == "") {
          this.$message({
            type: "error",
            message: "请必须添加姓名、性别、年龄",
          });
          return;
        }
      }
      //疾病是必填项
      if (form.ill_lib_id == "") {
        this.$message({
          type: "error",
          message: "请选择疾病",
        });
        return;
      }
      //不是必填项
      // if(form.share_title === '' || form.share_img === '' || form.share_content === ''){
      //   this.$message({
      //       type: "error",
      //       message: "请必须添加分享所需要的标题、图片和描述",
      //     });
      //     return;
      // }
      // console.log(form);
      this.axios.post(url, this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: str,
          });
          if (this.type == "new") {
            this.bangdingjibing(form.ill_lib_id, res.data.id);
          } else {
            this.bangdingjibing(form.ill_lib_id, this.id);
          }
        }
      });
      // this.distributeID.id = ;
      // this.distributeID.id = this.id;
      // this.distributedp = form.ill_lib_id
      //           this.distributedp
      // this.distributeID.id

      // this.$router.push({ path: "/userUpload", query: { type: 1 } });
      // this.$router.go(-1)
    },
    //分配科室
    distributeIll() {
      this.getillList();
      this.distribute = true;
      this.distributeID = this.arr;
    },
    selectdistributedp(val) {
      this.distributedp = val.name;
      this.dp = val.id;
      this.ill_name = val.name;
    },
    subdistribute() {
      this.axios
        .post(
          "/tag/check_gu_tag_by_illlibid",
          this.qs.stringify({
            ill_lib_id: this.dp,
            ill_lib_name: this.distributedp,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.distribute = false;
          }
        });
    },
    bangdingjibing(illid, _id) {
      this.axios
        .post(
          "/item/update_gu_item_illlibid",
          this.qs.stringify({ ill_lib_id: illid, id: _id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            // this.$message({
            //   type: "success",
            //   message: "绑定成功",
            // });
            console.log("绑定成功");
            this.distributedp = "";
            // this.$router.go(-1)
          }
        });
    },
    htmlEncode: function (html) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      temp.textContent != undefined
        ? (temp.textContent = html)
        : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    /*2.用浏览器内部转换器实现html解码（反转义）*/
    htmlDecode: function (text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.el-upload__input {
  opacity: 0;
}
.videochoice {
  float: left;
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
</style>
